export class Team {
    adminId:string;
    canEditAccount:boolean;
    canEditContact:boolean;
    canEditProcedure:boolean;
    canEditProject:boolean;
    canEditTeam:boolean;
    canEditUnit:boolean;
    canShowAccounts:boolean; 
    email:string;
    extension:string;
    id:string;
    isAdmin:boolean;
    isDeleted:boolean;
    isTeamMember:boolean;
    name:string;
    password:string;
    phone:string;
    timestamp:any;
    uid:string;
    canEditRuns:any
}