import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { API, Auth, Storage } from 'aws-amplify';
import { AlertController, NavController } from '@ionic/angular';
import { v4 as uuid } from 'uuid';
import { User } from '../modals/User';
import { Team } from '../modals/Team';
import { Event } from '../modals/Event';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  isLoggedIn: boolean = false;
  currentUser: User = new User();
  username: string;
  userLogo: string = "";

  teamUser:Team = new Team(); 
  procedureSortType: string;
  runsSortType: any;

  event:Event = new Event();

  constructor(public amplifyService: AmplifyService, public alertController: AlertController,
    private nav: NavController,) {

    this.teamUser = new Team();

    this.amplifyService.authStateChange$
      .subscribe(authState => {
        // console.log('authState', authState);
        this.isLoggedIn = authState.state === 'signedIn';

        if (this.isLoggedIn) {
          this.username = authState.user.username;
          this.currentUser = authState.user.attributes;

          if (this.currentUser["custom:adminId"]) {
            this.username = this.currentUser["custom:adminId"];
          }
          

          Auth.currentUserInfo()
            .then(res => {
              // console.log('res', res);
              this.currentUser = res.attributes;
              // debugger;
              localStorage.setItem('currentUserEmail',this.currentUser["email"]);

              if (this.currentUser["custom:adminId"]) {
                this.username = this.currentUser["custom:adminId"];
                this.teamUser.isTeamMember = true;
                this.getMemberDetails();
              }

              if (this.currentUser["custom:logo"]) {
                Storage.get(this.currentUser["custom:logo"])
                  .then(res => {
                    this.userLogo = res as string;
                  })
                  .catch(err => {
                    console.log(err);
                  });
              }

              if (this.currentUser["custom:isBlocked"] == '1') {
                this.presentAlert("Alert", null, "Your account is blocked. Please contact admin!", "", [{ text: 'Ok' }]);
                // alert("Your account is blocked. Please contact admin!");
                Auth.signOut();
              }

            });
          // this.nav.navigateRoot(['/home']);
        } else {
          this.isLoggedIn = false;
          this.currentUser = new User();
          this.username = "";
          this.userLogo = "";
          this.nav.navigateRoot(['/login']);
        }
      });
  }
  async presentAlert(header, subHeader, message, cssClas, Buttons) {
    const alert = await this.alertController.create({
      cssClass: cssClas,
      mode:'ios',
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: Buttons
    });

    await alert.present();
  }
  getMemberDetails() {

    API.get("TeamApi", `/team/team`, { body: {} })
      .then(async res => {
        this.teamUser = res.filter(x => x.email === this.currentUser.email)[0];
        // console.log('getMemberDetails', res)
      })
      .catch(err => {
        // alert(JSON.stringify(err.toJson()));
        this.presentAlert("Error!", null,JSON.stringify(err.toJson()), "",[{text: 'Ok'}]);
        console.log(err);
      });
  }

  deleteAccount() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        user.deleteUser((res) => {
          this.nav.navigateRoot(['/login']);
        });
      })
  }

  createEvent(event,roles){
    this.event.id = uuid();
    if(this.currentUser["custom:adminId"]){
      this.event.roles = roles;
    }else{
      this.event.roles = null;
    }    
    this.event.timestamp = Number(new Date());
    this.event.uid = this.username;
    this.event.event = event;
    this.event.userName = this.currentUser["custom:firstName"]+" "+this.currentUser["custom:lastName"];
    // this.event.terms = true;
    API.put("EventsApi", "/events", { body: this.event })
    .then(res => {
      // this.loading = false;
    })
    .catch(err => {
      // this.loading = false;
      // alert(JSON.stringify(err.toJson()));
      // this.presentAlert("Error!", null,JSON.stringify(err.toJson()), "",[{text: 'Ok'}]);
      console.log('eror',err);
    });
  }

  
}
