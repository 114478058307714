export class User {
    email:string;
    password:string;
    email_verified:boolean;
    sub:string;
    logo:string;
    custom:Custom = new Custom();
}

export class Custom {
    city:string;
    firstName:string;
    isBlocked:string;
    lastName:string;
    logo:string;
    phone:string;
    zip:string;
    address:string;
    state:string;
    country:string;
}