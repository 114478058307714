import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'search-results',
    loadChildren: () => import('./search-results/search-results.module').then( m => m.SearchResultsPageModule)
  },
  {
    path: 'account-detail',
    loadChildren: () => import('./account-detail/account-detail.module').then( m => m.AccountDetailPageModule)
  },
  {
    path: 'new-account',
    loadChildren: () => import('./new-account/new-account.module').then( m => m.NewAccountPageModule)
  },
  {
    path: 'new-project',
    loadChildren: () => import('./new-project/new-project.module').then( m => m.NewProjectPageModule)
  },
  {
    path: 'new-unit',
    loadChildren: () => import('./new-unit/new-unit.module').then( m => m.NewUnitPageModule)
  },
  {
    path: 'project-detail',
    loadChildren: () => import('./project-detail/project-detail.module').then( m => m.ProjectDetailPageModule)
  },
  {
    path: 'unit-detail',
    loadChildren: () => import('./unit-detail/unit-detail.module').then( m => m.UnitDetailPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'trash',
    loadChildren: () => import('./trash/trash.module').then( m => m.TrashPageModule)
  },
  {
    path: 'file-editor',
    loadChildren: () => import('./file-editor/file-editor.module').then( m => m.FileEditorPageModule)
  },
  {
    path: 'confirm-email',
    loadChildren: () => import('./confirm-email/confirm-email.module').then( m => m.ConfirmEmailPageModule)
  },
  {
    path: 'recover-password',
    loadChildren: () => import('./recover-password/recover-password.module').then( m => m.RecoverPasswordPageModule)
  },
  {
    path: 'procedure-detail',
    loadChildren: () => import('./procedure-detail/procedure-detail.module').then( m => m.ProcedureDetailPageModule)
  },
  {
    path: 'procedure-edit/:index',
    loadChildren: () => import('./procedure-edit/procedure-edit.module').then( m => m.ProcedureEditPageModule)
  },
  {
    path: 'procedure-create',
    loadChildren: () => import('./procedure-create/procedure-create.module').then( m => m.ProcedureCreatePageModule)
  },
  {
    path: 'reporting',
    loadChildren: () => import('./reporting/reporting.module').then( m => m.ReportingPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'contact-detail',
    loadChildren: () => import('./contact-detail/contact-detail.module').then( m => m.ContactDetailPageModule)
  },
  {
    path: 'new-contact',
    loadChildren: () => import('./new-contact/new-contact.module').then( m => m.NewContactPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'new-team',
    loadChildren: () => import('./new-team/new-team.module').then( m => m.NewTeamPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'task-create',
    loadChildren: () => import('./task-create/task-create.module').then( m => m.TaskCreatePageModule)
  },  {
    path: 'create-trigger',
    loadChildren: () => import('./create-trigger/create-trigger.module').then( m => m.CreateTriggerPageModule)
  },
  {
    path: 'create-folder',
    loadChildren: () => import('./create-folder/create-folder.module').then( m => m.CreateFolderPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
