import { Role } from "./Role";

export class Event {
    dateTime:string;
    event:string;
    id:string;
    roles:Role[] = [];
    timestamp:number;
    uid:string;
    userName:string;
}