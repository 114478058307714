import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgxLoadingModule } from 'ngx-loading';
import { AmplifyService } from 'aws-amplify-angular';
import { NgxSortableModule } from 'ngx-sortable';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { SignaturePadModule } from 'angular2-signaturepad';
// import { ScrollHideDirective } from './directives/scroll-hide.directive';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    TagInputModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true
    }),
    NgxSortableModule,
    NgxIonicImageViewerModule,
    SignaturePadModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AmplifyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
