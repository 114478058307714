import { MileStone } from "./MileStone";
import { Role } from "./Role";

export class Procedure {
    approval = [];
    assignedTo:string;
    autoForward:boolean;
    completedPercent:string | number;
    due:boolean;
    dueDate:string;
    dueTime:string;
    dueTimestamp:number;
    formatedDate:string;
    id:string;
    isChecked:boolean;
    isDeleted:boolean;
    isSelected:boolean;
    milestones:MileStone[] = [];
    overdue:boolean;
    pending:boolean;
    procedureId:string;
    roles:Role[] = [];
    role:Role = new Role();
    run:boolean;
    timestamp:number;
    title:string;
    uid:string;
    folderId:string;
}





